import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1><span className="welcome-text"></span> <span className="platform-name">Refine.</span></h1>
        <p className="slim-font">Coming Soon</p>
      </header>
      <footer>
        <p className="slim-font">© 2024 Refine. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
